import gql from 'graphql-tag';

const CentroCostoReporte = {
    getAll: (client, { periodo }, recursos) => client.query({
        query: gql`query centroCostoReporte($periodo: String!) {
            centroCostoReporte(periodo: $periodo) {
                ${recursos}
            }
        }`,
        variables: {
            periodo
        },
        fetchPolicy: 'no-cache'
    }),
    getByOperacionId: (client, { operacion_id, centro_costo_id, periodo }, recursos) => client.query({
        query: gql`query centroCostoReporteByOperacionId($operacion_id: String!, $centro_costo_id: String!, $periodo: String!) {
            centroCostoReporteByOperacionId(operacion_id: $operacion_id, centro_costo_id: $centro_costo_id, periodo: $periodo) {
                ${recursos}
            }
        }`,
        variables: {
            operacion_id,
            centro_costo_id,
            periodo
        },
        fetchPolicy: 'no-cache'
    })
}

export default CentroCostoReporte;